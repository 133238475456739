import {
  faAirConditioner,
  faCheck,
  faFileImport,
  faSpinner,
  faTimes,
  faUserTie
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button, Fab, TableCell, Typography
} from '@material-ui/core';

import {
  ButtonBlue,
  ButtonBlueLight,
  ButtonFloating,
  ButtonText,
  CustomIcon,
  FlexContainer,
  GenericListContainer,
  GenericListFilters,
  GenericTable,
  ReactTour,
  SkeletonTableSmall,
  SubtitlePage,
  Text,
  TextAddress,
  TextError
} from 'components';
import { useEffectAfterRender, useExcelImport, useModal } from 'hooks';
import { observer } from 'mobx-react-lite';
import { EntitySummaryItem, LegalEntityFilter } from 'models';
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';

import Media from 'react-media';
import { useHistory, useLocation } from 'react-router-dom';

import { faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { useSnackbar } from 'notistack';
import { DocumentsService, EquipmentOwnersService, EquipmentsService } from 'services';
import shortid from 'shortid';
import { APPLICATION_ROLES } from 'utils/constants';
import {
  DocumentHelper,
  FilterHelper,
  translate,
  UserHelper
} from 'utils/helpers';
import { HeaderType } from 'utils/types';
import { FilterItem } from 'utils/types/FilterItem';
import { EquipmentOwnerListFilters } from './EquipmentOwnerListFilters';

const listHeaders: (
  handleEquipmentAccessClick: (e: any, row: EntitySummaryItem) => void
) => HeaderType<EntitySummaryItem>[] = handleEquipmentAccessClick => [
  {
    name: 'lastName',
    label: translate('common.lastName'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {`${row.firstName} ${row.lastName}`}
      </TableCell>
    )
  }, {
    name: 'siret',
    label: translate('common.siret'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.institutionIdentifier}
      </TableCell>
    )
  }, {
    name: 'address',
    label: translate('common.address'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row?.address ? (
          <TextAddress address={row.address} />
        ) : '-'}
      </TableCell>
    )
  }, {
    name: 'validated',
    label: translate('common.equipmentOwnerValidated'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <FontAwesomeIcon color={row.validated ? 'var(--green)' : 'var(--red)'} icon={row.validated ? faCheck : faTimes} />
      </TableCell>
    )
  }, {
    name: 'equipmentsValidated',
    label: translate('common.equipmentsValidated'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <FontAwesomeIcon color={row.equipmentsValidated ? 'var(--green)' : 'var(--red)'} icon={row.equipmentsValidated ? faCheck : faTimes} />
      </TableCell>
    )
  }, {
    name: 'equipments',
    label: '',
    template: row => (
      <TableCell key={shortid.generate()}>
        <ButtonText
          color="var(--blue)"
          underlineHover
          onClick={e => handleEquipmentAccessClick(e, row)}
        >
          <FontAwesomeIcon icon={faAirConditioner} />
          {translate('pageHome.cardValidations.EQUIPMENT')}
        </ButtonText>
      </TableCell>
    )
  }
];

export const EquipmentOwnerList = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { open } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const filterKey = 'equipmentOwnerList';
  const locationState: any = location.state;
  const historyFilter: FilterItem<LegalEntityFilter>[] = (locationState && locationState.filters);

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [equipmentOwnerList, setEquipmentOwnerList] = useState<EntitySummaryItem[]>([]);

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(historyFilter);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [listSize, setListSize] = useState(0);

  const getEquipmentOwnerList = useCallback(({ pageChange = false } = {}) => {
    setIsLoading(true);
    EquipmentOwnersService.getEquipmentOwnerList(FilterHelper.buildFilterForm(filters, search), currentPage)
      .then((response) => {
        if (response) {
          let allEquipmentOwner = response.content;
          if (pageChange && currentPage > 1) {
            allEquipmentOwner = [].concat(...equipmentOwnerList, response.content);
          }
          setEquipmentOwnerList(allEquipmentOwner);
          setMaxPage(response.totalPages);
          setListSize(response.totalElements);
        }
      }).finally(() => setIsLoading(false));
  }, [currentPage, equipmentOwnerList, filters, search]);

  const reloadList = useCallback(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (currentPage === 1) getEquipmentOwnerList({ pageChange: false });
    else setCurrentPage(1);
  }, [currentPage, getEquipmentOwnerList]);

  useEffectAfterRender(() => {
    if (currentPage === 1) getEquipmentOwnerList({ pageChange: false });
    else setCurrentPage(1);
    // eslint-disable-next-line
  }, [filters, search]);

  useEffect(() => {
    getEquipmentOwnerList({ pageChange: currentPage !== 1 });
    // eslint-disable-next-line
  }, [currentPage]);

  const createEquipmentOwner = useCallback((form) => {
    if (form.hashId) {
      return EquipmentOwnersService.updateEquipmentOwner(form.hashId, form).then(() => {
        open({ type: 'CONFIRM', text: translate('confirms.equipmentOwnerList.update'), onConfirm: reloadList });
      });
    }

    return EquipmentOwnersService.createEquipmentOwner(form).then(() => {
      open({ type: 'CONFIRM', text: translate('confirms.equipmentOwnerList.create'), onConfirm: reloadList });
    });
  }, [open, reloadList]);

  const {
    isImporting: isImportingEquipments,
    importFunc: importEquipments
  } = useExcelImport(EquipmentsService.importEquipments, reloadList);

  const {
    isImporting: isImportingEquipmentOwners,
    importFunc: importEquipmentOwners
  } = useExcelImport(EquipmentsService.importEquipmentOwners, reloadList);

  const EXPORT_MAIL_SIZE = 1000;
  const exportEquipments = useCallback(
    () => {
      setIsExporting(true);
      if (Number(listSize) > EXPORT_MAIL_SIZE) {
        const timeout = new Promise((res: any) => {
          setTimeout(() => res(), 1000);
        });
        const controller = new AbortController();
        const { signal } = controller;
        Promise.race([EquipmentsService.exportAsyncEquipments(FilterHelper.buildFilterForm(filters, search), signal), timeout])
          .then(() => {
            enqueueSnackbar(translate('confirms.movement.exportByEmail'), { variant: 'success', autoHideDuration: 5000 });
            controller.abort();
          })
          .catch((error) => enqueueSnackbar(error, { variant: 'error', autoHideDuration: 5000 }))
          .finally(() => setIsExporting(false));
      } else {
        EquipmentsService.exportEquipments(FilterHelper.buildFilterForm(filters, search)).then((resp) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = DocumentHelper.getExcelWithBase64(resp);
          downloadLink.download = resp.name;
          downloadLink.click();
        }).catch((error) => enqueueSnackbar(error, { variant: 'error', autoHideDuration: 5000 }))
          .finally(() => setIsExporting(false));
      }
    },
    [listSize, filters, search, enqueueSnackbar]
  );

  const displayModalEquipmentOwner = useCallback(() => open({
    type: 'ADD_EQUIPMENT_OWNER',
    onSubmit: createEquipmentOwner
  }), [createEquipmentOwner, open]);

  const handleLoadMore = useCallback(() => !isLoading && setCurrentPage(currentPage + 1), [isLoading, currentPage]);

  const handleEquipmentAccessClick = useCallback((
    e: any,
    row: EntitySummaryItem
  ) => {
    e && e.stopPropagation();
    history.push(`/detenteur/${row.id}`, historyFilter && {
      state: {
        filters: historyFilter
      }
    });
  }, [history, historyFilter]);

  const renderFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <EquipmentOwnerListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  const renderButtonCreateMobile = useCallback(() => (
    <ButtonFloating
      dataCy="createEquipmentOwner"
      onClick={displayModalEquipmentOwner}
    >
      <Fab aria-label={translate('button.createEquipmentOwner')} color="primary">
        <CustomIcon icon={<FontAwesomeIcon icon={faUserTie} />} marginLeft="0.6rem" />
      </Fab>
    </ButtonFloating>
  ), [displayModalEquipmentOwner]);

  const renderButtonExport = useCallback(() => (
    <ButtonBlueLight
        data-cy="exportEquipments"
        data-tour="step-equipments-export"
        onClick={exportEquipments}
      >
      <FontAwesomeIcon icon={isExporting ? faSpinner : faFileExcel} spin={isExporting} />
      {translate('button.exportEquipments')}
    </ButtonBlueLight>
  ), [exportEquipments, isExporting]);

  const renderButtonContainer = useCallback(() => (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <ButtonBlue
          data-cy="createNewEquipmentOwner"
          data-tour="step-equipmentOwner-create"
          onClick={displayModalEquipmentOwner}
        >
          <FontAwesomeIcon icon={faUserTie} />
          {translate('button.createEquipmentOwner')}
        </ButtonBlue>

        <Media
          query="(min-width: 50px)"
          render={renderButtonExport}
        />
      </div>
      {UserHelper.hasAccessRight([APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN]) && (
        <Fragment>
          <br />
          <br />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <ButtonBlueLight
                data-cy="importEquipmentOwner"
                data-tour="step-intervention-equipmentOwnerImport"
                onClick={importEquipmentOwners}
              >
                {isImportingEquipmentOwners ? (
                  <Fragment>
                    <FontAwesomeIcon icon={faSpinner} spin />
                    {translate('button.importing')}
                  </Fragment>
                ) : (
                  <Fragment>
                    <FontAwesomeIcon icon={faFileImport} />
                    {translate('button.importEquipmentOwners')}
                  </Fragment>
                )}
              </ButtonBlueLight>
              <Button onClick={() => DocumentsService.getImportFile('equipmentOwners')}>
                <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                  {translate('common.exampleFile')}
                </Text>
              </Button>
            </div>
            <div>
              <Button onClick={() => DocumentsService.getImportFile('equipments')}>
                <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                  {translate('common.exampleFile')}
                </Text>
              </Button>
              <ButtonBlueLight
                data-cy="importEquipment"
                data-tour="step-intervention-equipmentImport"
                onClick={importEquipments}
              >
                {isImportingEquipments ? (
                  <Fragment>
                    <FontAwesomeIcon icon={faSpinner} spin />
                    {translate('button.importing')}
                  </Fragment>
                ) : (
                  <Fragment>
                    <FontAwesomeIcon icon={faFileImport} />
                    {translate('button.importEquipments')}
                  </Fragment>
                )}
              </ButtonBlueLight>
            </div>
          </div>
          <br />
        </Fragment>
      )}
    </div>
  ), [isImportingEquipments, isImportingEquipmentOwners, displayModalEquipmentOwner, importEquipments, importEquipmentOwners, renderButtonExport]);

  return (
    <>
      <GenericListContainer>
        <SubtitlePage>
          {translate('pageEquipmentOwnerList.title')}
        </SubtitlePage>

        <Typography align="center" gutterBottom>{translate('pageEquipmentOwnerList.notice')}</Typography>

        {renderButtonContainer()}

        <GenericListFilters
          ComponentFilter={renderFilters}
          disabled={false}
          filterKey={filterKey}
          filters={filters}
          isVisible
          search={search}
          setFilters={setFilters}
          setSearch={setSearch}
          withSidePanel
        />

        <div data-tour="step-admin-equipmentOwnerList">
          {!isLoading && equipmentOwnerList.length === 0
            ? (
              <FlexContainer alignItems="center" flexDirection="column">
                <TextError>{translate('errors.noEquipmentOwner')}</TextError>
                <ButtonBlue
                  data-cy="createEquipmentOwner"
                  onClick={displayModalEquipmentOwner}
                >
                  <FontAwesomeIcon icon={faUserTie} />
                  {translate('button.createEquipmentOwner')}
                </ButtonBlue>
              </FlexContainer>
            ) : (
              <GenericTable<EntitySummaryItem>
                dataCy="equipmentOwnerList"
                hasMore={currentPage < maxPage}
                headers={listHeaders(handleEquipmentAccessClick)}
                loadMore={handleLoadMore}
                rows={equipmentOwnerList}
                total={listSize}
                onRowClick={row => handleEquipmentAccessClick(null, row)}
              />
            )
          }
        </div>

        {isLoading && <SkeletonTableSmall />}

        <ReactTour steps={[]} />

        <Media
          query="(max-width: 1080px)"
          render={renderButtonCreateMobile}
        />
      </GenericListContainer>
    </>
  );
});
